import { Link } from 'react-router-dom'
import Page from '../components/Layout/Page'
import Testimonial from '../components/Testimonial'

export default function BecomeAnAffiliate() {
  return (
    <Page title="Become an Affiliate">
      <div className="mt-5 lg:grid lg:grid-cols-2 lg:gap-8">
        <div className="lg:col-start-2">
          <div className="prose prose-indigo text-gray-500 mx-auto max-w-none lg:row-start-1 lg:col-start-1">
            <p>
              CollegeDegrees.com is looking for partners to join our affiliate program. 
              We are taking applications for only the highest-quality traffic providers who can generate online education traffic via organic search only. 
              We are committed to providing the highest-quality leads to our school partners, and we are seeking like-minded affiliate partners to join our growing business. 
              CollegeDegrees.com has built relationships with the most reputable and innovative schools in online education, and we can help you leverage your site traffic to generate optimum lead volume. 
              We offer competitive pricing in exchange for premium leads.
            </p>
            <p>
              If you own an education domain and would like to find out more about our rewarding affiliate program, please <Link to='/contact' className='text-sky-700 hover:text-sky-900'>contact us</Link> today.
            </p>
          </div>
        </div>
        <div className="mt-5 lg:mt-0 relative lg:row-start-1 lg:col-start-1">
          <Testimonial />
        </div>
      </div>
    </Page>

  )
}
