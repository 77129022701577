import { useEffect } from 'react'
import Page from '../components/Layout/Page'

const MHMD = () => {
  useEffect(() => {
    // Settings Script
    const settingsScript = document.createElement('script');
    settingsScript.src = "https://privacyportal-cdn.onetrust.com/privacy-notice-scripts/otnotice-1.0.min.js";
    settingsScript.id = 'otprivacy-notice-script';
    settingsScript.type = 'text/javascript';
    settingsScript.async = true;
    settingsScript.innerHTML = 'settings="eyJjYWxsYmFja1VybCI6Imh0dHBzOi8vcHJpdmFjeXBvcnRhbC5vbmV0cnVzdC5jb20vcmVxdWVzdC92MS9wcml2YWN5Tm90aWNlcy9zdGF0cy92aWV3cyJ9"';
    document.body.appendChild(settingsScript);
    // OneTrust Script
    const onetrustScript = document.createElement('script');
    onetrustScript.type = 'text/javascript';
    onetrustScript.async = true;
    onetrustScript.innerHTML = `
    OneTrust.NoticeApi.Initialized.then(function () {
      OneTrust.NoticeApi.LoadNotices(["https://privacyportal-cdn.onetrust.com/79ba7c84-ebc2-4740-8d11-bf1cc4501e59/privacy-notices/280cb967-7068-407a-b8f0-e4515baa2cc5.json"]);
    });
    `;
    document.body.appendChild(onetrustScript);
    return () => {
      document.body.removeChild(settingsScript);
      document.body.removeChild(onetrustScript);
    }
  }, []);
  return (
    <Page title="Consumer Health Data Privacy Policy">
      <div className="mt-5 prose max-w-none prose-sky text-gray-500 prose-a:text-sky-700">
        {/* OneTrust Privacy Notice start */}
        {/* Container in which the privacy notice will be rendered */}
        <div id="otnotice-280cb967-7068-407a-b8f0-e4515baa2cc5" class="otnotice"></div>
      </div>
    </Page>
  )
}

export default MHMD