import Page from '../components/Layout/Page'

const AboutUs = () => {
  return (
    <Page title="About">
      <div className="mt-5 prose max-w-none prose-sky text-gray-500 prose-a:text-sky-700">
        <p>
          CollegeDegrees.com is the Education Division of <a href="http://www.cmn.com/">Consumer Media Network</a>. 
          We help consumers navigate the vast and often complex world of higher education. 
          With so many options for pursuing education today, consumers need reliable information more than ever.
        </p>
        <p>  
          As a leading consumer advocate for online education, we help students navigate their way through this popular learning platform and avoid the pitfalls that college students, online and on ground, often face. 
          We provide the highest quality content and expert advice to help consumers make the best investment of their time and money.
        </p>
        <p>
          CollegeDegrees.com believes that the future of education is happening online, and we are committed to making it a brighter one.
        </p>
      </div>
    </Page>
  )
}

export default AboutUs