import Page from '../components/Layout/Page'
import Testimonial from '../components/Testimonial'

const schools = [
  {
    name: 'Post University',
    img: 'https://simple-storage-server.highereducation.com/post-university.png?w=160'
  },
  {
    name: 'Rasmussen College',
    img: 'https://simple-storage-server.highereducation.com/rasmussen-college.png?w=160'
  },
  {
    name: 'American InterContinental University',
    img: 'https://simple-storage-server.highereducation.com/american-intercontinental-university.png?w=160'
  },
  {
    name: 'Everest University',
    img: 'https://simple-storage-server.highereducation.com/everest-university.png?w=160'
  },
  {
    name: 'Capella University',
    img: 'https://simple-storage-server.highereducation.com/capella-university.png?w=160'
  },
  {
    name: 'Walden University',
    img: 'https://simple-storage-server.highereducation.com/walden-university.png?w=160'
  },
  {
    name: 'Saint Leo University Online',
    img: 'https://simple-storage-server.highereducation.com/saint-leo-university-online.png?w=160',
  },
  {
    name: 'Kaplan University',
    img: 'https://simple-storage-server.highereducation.com/kaplan-university.png?w=160',
  },
  {
    name: 'San Joaquin Valley College',
    img: 'https://simple-storage-server.highereducation.com/san-joaquin-valley-college.png?w=160',
  },
  {
    name: 'Philadelphia University',
    img: 'https://simple-storage-server.highereducation.com/philadelphia-university.png?w=160',
  },
  {
    name: 'The College Network',
    img: 'https://simple-storage-server.highereducation.com/the-college-network.png?w=160',
  },
  {
    name: 'Ashford University',
    img: 'https://simple-storage-server.highereducation.com/ashford-university.png?w=160',
  },
  {
    name: 'University of the Southwest',
    img: 'https://simple-storage-server.highereducation.com/university-of-the-southwest.png?w=160',
  },
  {
    name: 'Robert Morris University',
    img: 'https://simple-storage-server.highereducation.com/robert-morris-university.png?w=160',
  },
  {
    name: 'University of North Carolina',
    img: 'https://simple-storage-server.highereducation.com/university-of-north-carolina.png?w=160',
  },
  {
    name: 'Ultimate Medical Academy	',
    img: 'https://simple-storage-server.highereducation.com/ultimate-medical-academy.png?w=160',
  },
  {
    name: 'Strayer University',
    img: 'https://simple-storage-server.highereducation.com/strayer-university.png?w=160',
  },
  {
    name: 'Jones International University',
    img: 'https://simple-storage-server.highereducation.com/jones-international-university.png?w=160',
  },
  {
    name: 'University of Southern California — School of Social Work',
    img: 'https://simple-storage-server.highereducation.com/university-of-southern-california-school-of-social-work.png?w=160',
  },
  {
    name: 'Boston University',
    img: 'https://simple-storage-server.highereducation.com/boston-university.png?w=160',
  },
  {
    name: 'Ohio University',
    img: 'https://simple-storage-server.highereducation.com/ohio-university.png?w=160',
  },
  {
    name: 'Salem International University Online',
    img: 'https://simple-storage-server.highereducation.com/salem-university.png?w=160',
  },
  {
    name: 'Georgetown University',
    img: 'https://simple-storage-server.highereducation.com/georgetown-university.png?w=160',
  },
  {
    name: 'Northeastern University — School of Nursing',
    img: 'https://simple-storage-server.highereducation.com/northeastern-university-school-of-nursing.png?w=160',
  },
  {
    name: 'Villanova University',
    img: 'https://simple-storage-server.highereducation.com/villanova-university.png?w=160',
  },
  {
    name: 'University of San Francisco',
    img: 'https://simple-storage-server.highereducation.com/university-of-san-francisco.png?w=160',
  },
]
export default function SchoolPartners() {
  return (
    <Page title="School Partners">
      <div className="mt-5 lg:grid lg:grid-cols-2 lg:gap-8">
        <div className="lg:col-start-2">
          <div className="prose prose-indigo text-gray-500 mx-auto max-w-none lg:row-start-1 lg:col-start-1">
            <p>
              CollegeDegrees.com works with some of the nation's most innovative schools to further the reach of online education.
              Our partners value our quality approach to connecting prospective students with educational opportunities.
              We have built a solid reputation for integrity and transparency.
            </p>
            <p>
              The institutions we work with hail from across the educational spectrum, from single campus schools to giant university systems, from public to private.
              We strive to partner with schools that will offer our users the widest possible choice of courses and degree programs.
            </p>
            <p>
              As a group, our partner schools represent the changing world of higher education.
              The schools thrive by leveraging the technological revolution to enlighten more minds than ever before.
            </p>
            <p>
              CollegeDegrees.com aims to extend that mission through innovative partnerships with online education establishments.
            </p>
          </div>
        </div>
        <div className="mt-5 lg:mt-0 relative lg:row-start-1 lg:col-start-1">
          <Testimonial />
        </div>
      </div>

      <div className="mt-6 grid grid-cols-2 gap-0.5 md:grid-cols-3 lg:mt-8">
        {schools.map(school => (
          <div key={school.name} className="col-span-1 flex flex-col text-center justify-center p-4 bg-gray-50">
            <div className="flex grow justify-center content-center	items-center">
              <div>
                <img
                  className="max-h-18 mx-auto"
                  src={school.img}
                  alt={school.name}
                />
              </div>
            </div>
            <p className='text-sm font-medium text-gray-500 flex-end mt-2'>{school.name}</p>
          </div>
        ))}
      </div>
    </Page>

  )
}
