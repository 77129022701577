import Page from "../components/Layout/Page";

export default function DoNotSellOrShareInfo() {
  return (
    <Page title="Do not sell or share my personal information ">
      <div class="h-screen">
        <iframe
          src="https://privacyportal.onetrust.com/webform/79ba7c84-ebc2-4740-8d11-bf1cc4501e59/d6f594bb-5064-4af2-9b77-710c0d6c227c"
          id="otnotice-iframe"
          title="OneTrust Notice"
          class="w-full h-full"
        ></iframe>
      </div>
      <script>
        {`
        function getCookie(name) {
            var value = "; " + document.cookie;
            var parts = value.split("; " + name + "=");
            if (parts.length == 2)
                return parts.pop().split(";").shift();
        }

        var groupID = ['C0004', 'C0005']; // Example group IDs to toggle off

        var date = new Date();
        date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000));
        var expires = "; expires=" + date.toGMTString();

        var formSubmit = false;

        function updateTargeting(ID) {
            var cookie = getCookie("OptanonConsent");
            for (var i = 0; i < ID.length; i++) {
                cookie = cookie.replace('%2C' + ID[i] + '%3A0', "%2C" + ID[i] + "%3A0");
                cookie = cookie.replace('%2C' + ID[i] + '%3A1', "%2C" + ID[i] + "%3A0");
                var cookieName = "OptanonConsent";
                OptanonActiveGroups = OptanonActiveGroups.replace("," + ID[i] + ",", ",");
                OnetrustActiveGroups = OnetrustActiveGroups.replace("," + ID[i] + ",", ",");
            }
            document.cookie = cookieName + "=" + cookie + expires + ";path=/; Samesite=Lax;";
            console.log("OnetrustActiveGroups = " + OnetrustActiveGroups);
            console.log("Targeting Cookies disabled!");
        }

        window.addEventListener("message", function(event) {
            if (event.origin != 'https://privacyportal-cdn.onetrust.com') {
                console.log("Not an event from the expected iframe");
                return;
            }

            updateTargeting(groupID);
            formSubmit = true;
            console.log("formSubmit = " + formSubmit);
            window.alert("Event received from iframe: data = " + event.data + "; event.origin: " + event.origin);
            console.log("event: " + updateEvent);
        });
        `}
      </script>
    </Page>
  );
}