import React from 'react'
import Page from '../components/Layout/Page'

const ContactUs = () => {
  return (
    <Page title="Contact">
      <div className="mt-5">
        <h3 className="text-lg font-bold text-sky-700">Company Headquarters</h3>
        <dl className="mt-2 text-base text-warm-gray-500">
          <div className="mt-1">
            <dt className="sr-only">Direction</dt>
            <dd>
              Red Ventures<br/>
              1101 Red Ventures Drive<br/>
              Fort Mill, SC 29707
            </dd>
          </div>
          <div className="mt-1">
            <dt className="sr-only">Email</dt>
            <dd><a href="mailto:info@collegedegrees.com">info@collegedegrees.com</a></dd>
          </div>
        </dl>
      </div>
    </Page>
  )
}

export default ContactUs