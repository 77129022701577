import React from 'react'
import List from '../components/ConsumerFacts/List'
import Page from '../components/Layout/Page'
const ConsumerFacts = () => {
  return (
    <Page title="Consumer Facts for the College Student">
      <div className="mt-5 md:grid md:grid-cols-3 md:gap-8">
        <div className="relative md:row-start-1 md:col-start-3">
          <div className="relative text-base mx-auto max-w-prose md:max-w-none">
            <figure>
              <div className="aspect-w-12 aspect-h-7 md:aspect-none">
                <img
                  className="rounded-lg shadow-lg object-cover object-center"
                  src="https://res.cloudinary.com/highereducation/image/upload/f_auto,fl_lossy,q_auto:eco/v1/CollegeDegrees.com/2011/11/chalkboard.jpg"
                  alt="chalkboard"
                  width={1184}
                  height={1376}
                />
              </div>
            </figure>
          </div>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <div className="prose prose-indigo text-gray-500 mx-auto md:max-w-none md:row-start-1 md:col-start-1">
            <p>
              There has been a lot of media attention lately on whether or not college is worth the money consumers pay.
              College costs are skyrocketing and creating burdensome debt, while at the same time, the unemployment rate is steadily growing.
              Is college the next "bubble" about to burst?
            </p>
            <p>
              Consumers are also concerned with whether an education from an online university is on the same playing field as one earned through traditional means, and whether an online degree will be respected in the workplace.
              We set out to find the facts consumers need to know about college education as a whole, and specifically, about the value of online higher education.
              The facts may surprise you.
            </p>
            <p>
              One thing we found is that year after year, college graduates continue to earn more money on average than their counterparts without degrees.
              The networking that takes place in college often leads to jobs, whether online or on campus.
              Online education allows working adults and parents the opportunity to go back and earn a degree on their own time without putting their careers on hold, giving them the chance to make a career change or advance in their current career.
              In fact, not only has online education grown exponentially, but more and more employers are becoming familiar with it and accepting of it when it comes to hiring employees with online degrees.
            </p>
            <p>
              However, consumers still should be wary.
              College is a huge investment and unaccredited institutions or overpriced programs with few career prospects may leave graduates with jobs that don't pay enough for them to repay their student loans.
              Also, with all the options students have today, it's important to know which degree path is the best fit for your life.
            </p>
            <p>
              Take a look at the following consumer-friendly statistics when considering what college you should choose.
            </p>
          </div>
        </div>
      </div>
      <List />
    </Page>
  )
}

export default ConsumerFacts

