import { Routes, Route } from "react-router-dom";

import AboutUs from "./views/AboutUs";
import AdvertisingDisclosure from "./views/AdvertisingDisclosure";
import TermsOfService from "./views/TermsOfService";
import BecomeAnAffiliate from "./views/BecomeAnAffiliate";
import ConsumerFacts from "./views/ConsumerFacts";
import ContactUs from "./views/ContactUs";
import Home from "./views/Home";
import PrivacyPolicy from "./views/PrivacyPolicy";
import MHMD from "./views/MHMD";
import SchoolPartners from "./views/SchoolPartners";
import Navbar from "./components/Layout/Navbar";
import Footer from "./components/Layout/Footer";
import ScrollToTop from "./components/ScrollToTop";
import DoNotSellOrShareInfo from "./views/DoNotSellOrShareInfo";
function App() {
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="flex-grow">
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<AboutUs />} />
            <Route path="consumer-facts" element={<ConsumerFacts />} />
            <Route path="school-partners" element={<SchoolPartners />} />
            <Route path="affiliate-program" element={<BecomeAnAffiliate />} />
            <Route path="contact" element={<ContactUs />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="advertising-disclosure" element={<AdvertisingDisclosure />} />
            <Route path="terms-of-service" element={<TermsOfService />} />
            <Route path="consumer-health-data-privacy-policy" element={<MHMD />} />
            <Route path="do-not-sell-my-personal-information" element={<DoNotSellOrShareInfo />} />
          </Routes>
        </ScrollToTop>
      </main>
      <Footer />
    </div>
  );
}

export default App;