import React from 'react'

const Testimonial = () => {
  return (
    <div className="relative p-8 rounded-2xl shadow-xl overflow-hidden bg-sky-700">
      <div className="relative">
        <h3 className="mt-2 text-white text-xl font-extrabold tracking-tight sm:text-2xl">Testimonials</h3>
        <blockquote className="mt-4">
          <div className="relative font-medium text-white md:flex-grow">
            <svg
              className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-sky-400"
              fill="currentColor"
              viewBox="0 0 32 32"
              aria-hidden="true"
            >
              <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
            </svg>
            <p className="relative">
              College Degrees provides Liberty University Online with high quality leads that convert extremely well through the enrollment funnel. We are impressed with the responsiveness of our account manager, as well as the proactive approach to updating content, searching out new opportunities and looking out for our best interest. Furthermore, College Degrees continuously places effort on optimizing our account and strategizing so that Liberty will continue to receive high volume, high quality leads. Our overall experience with College Degrees is outstanding and we are extremely pleased with our relationship.
            </p>
          </div>

          <footer className="mt-4">
            <p className="text-base font-semibold text-white">Liberty University</p>
          </footer>
        </blockquote>
      </div>
    </div>
  )
}

export default Testimonial