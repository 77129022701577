/* This example requires Tailwind CSS v2.0+ */
import { ChevronRightIcon, LightningBoltIcon } from '@heroicons/react/solid'
import { Disclosure } from '@headlessui/react'

const statistics = [
  {
    title: 'College Is Still Worth It',
    content: [
      <span>People age 25 and older who hold college degrees earn more money and have lower rates of unemployment on average than those who only hold a high school diploma, according to the <a href="http://www.bls.gov/emp/ep_chart_001.htm">U.S. Bureau of Labor Statistics</a>. Average weekly earnings for bachelor's degree holders were $1,038, compared to $626 for those with only a high school diploma in 2010.</span>,
      <span>According to <a href="http://acrn.ovae.org/parents/afterHS.htm">America's Career Resource Network</a>, 48 of the 50 fastest-growing jobs in the nation require some type of education after high school. These include many in-demand careers in healthcare and technology.</span>,
      <span>One's lifelong earning potential during their working years is far greater for those with bachelor's degrees than for those with a high school diploma, according to the <a href="http://militaryfinance.umuc.edu/education/">North American Military Financial Education Center</a>. Over an adult's 45-year working life, someone with a high school diploma would earn $1.5 million on average, while someone with a bachelor's degree would earn $2.6 million on average.</span>,
      <span>Attending graduate school leads to increased earnings across every discipline, according to <a href=" http://www9.georgetown.edu/grad/gppi/hpi/cew/pdfs/whatsitworth-select.pdf">study</a> by the Georgetown Center on Education and the Workforce.</span>,
      <span>Many college graduates point out that networking with other likeminded individuals in college not only led to personal growth and a more global perspective, but also helped them find a job or advance in their career, the North American Military Financial Education Center noted.</span>,
    ]
  },
  {
    title: 'More Students Are Enrolling Online',
    content: [
      <span>More than 5.6 million students were taking at least one online course in the fall term of 2009, according to the <a href="http://sloanconsortium.org/publications/survey/survey05.asp">2010 Sloan Survey of Online Learning</a>. This is a million more students enrolled in online courses than the previous year, the survey showed. In fact, nearly 30% of all college students take at least one online course.</span>,
      <span>Online enrollment increased 19% between 2002 and 2008, according to a <a href="http://sloanconsortium.org/publications/survey/pdf/learningondemand.pdf">report</a> by Babson Survey Research Group and the Sloan Consortium.</span>,
      <span>Community colleges have reported a 9% increase in enrollments in distance education programs between fall 2009 and fall 2010, according to a national survey by the Instructional Technology Council, <a href="http://www.insidehighered.com/news/2011/05/18/community_college_distance_education_enrollments_continues_to_grow"><i>Inside Higher Ed</i></a> notes.</span>,
      <span>Online degrees continue to grow in acceptance. Not only have publications like the journal <i>Nature</i> noted the <a href="http://www.nature.com/naturejobs/2011/111020/full/nj7369-417a.html">growing acceptance of online degree programs</a>, but news outlets like <a href="http://www.cnn.com/2010/LIVING/worklife/03/29/cb.employers.online.education/index.html">CNN Living</a> have chronicled this occurrence as well, noting that for many employers, a degree is a degree, regardless of the manner in which it was earned.</span>,
      <span>Rising enrollment in online classes is linked to an increasing number of nontraditional students looking for flexible higher education options that do not disrupt their ability to work full time. Non-traditional students include older students, working students, and parents. According to an <a href="http://www.npr.org/templates/story/story.php?storyId=129402669">NPR segment</a>, the majority of college students are non-traditional in that they work, have families, and go to school part time. <a href="http://www.fastweb.com/student-life/articles/350-college-tips-for-nontraditional-students">Fastweb</a> recommends online classes for nontraditional students who need more flexibility without a commute.</span>,
    ]
  },
  {
    title: 'Online Learning Reflects Modern Reality',
    content: [
      <span>Three-quarters of post-secondary institutions report that the downturn in the economy has increased demand for online courses, while only a half of institutions report an increased demand for face-to-face courses and programs, according to a <a href="http://sloanconsortium.org/publications/survey/pdf/class_differences.pdf">report</a> by Babson Survey Research Group and the Sloan Consortium.</span>,
      <span>In 2010, 63% of all responding institutions said online education was an essential part of their long-term strategy, compared to 59% in 2009, according to the Sloan report.</span>,
      <span>Nearly 80% of responding organizations said they had hired applicants with online degrees in the past 12 months, according to a survey by the <a href="http://www.shrm.org/Research/SurveyFindings/Articles/Pages/HiringPracticesandAttitudes.aspx">Society for Human Resource Management</a>.</span>,
      <span>In a <a href="http://www.camden.lib.nj.us/survey/results.htm">survey</a> of individuals working in libraries or library-related industries, 82% of respondents said they would hire someone with a Master of Library Science (MLS) degree earned online, and 43% believed the online degree was equivalent to a traditional degree.</span>,
      <span>Employers are increasingly accepting of online degrees, and corporations have given a nod to online learning by providing tuition reimbursement to their employers for online programs, thereby recognizing that they are a viable option for working adults, according to a <a href="http://www.wgu.edu/about_WGU/george_lorenzo.pdf?">report</a> commissioned by Western Governors University.</span>,
      <span>Educators are realizing that the current higher education model that centers on the in-classroom lecture is outdated and doesn't reflect advances made by modern technology, according to a <a href="http://americanradioworks.publicradio.org/features/tomorrows-college/lectures/">public radio series</a> aimed at rethinking the way college students learn.</span>,
      <span>Many in academia see online learning as a key to advancing their mission, putting higher education in the hands of those who might not otherwise have access to it, according to a report from the <a href="http://www.nmc.org/pdf/Future-of-Higher-Ed-(NMC).pdf">Economist Intelligence Unit</a>.</span>,
    ]
  },
  {
    title: 'Online Degree Programs Are Just as Demanding',
    content: [
      <span>Evidence suggests that online learning is more suitable for students who are mature, driven, and disciplined, according to a <a href="http://iacis.org/iis/2011_iis/number_2/244-249_AL2011_1718.pdf">2011 report</a> published by the International Association for Computer Information Systems.</span>,
      <span><a href="http://www.mnsu.edu/ext/online/skills.html">Minnesota State University</a> lists computer literacy, strong reading and writing skills, self-motivation, the ability to learn independently, and time management as skills required for online learning.</span>,
      <span>Time management skills may be the biggest factor in whether a student succeeds in an online class, according to <a href="http://jetnet.jccmi.edu/mod/book/view.php?id=183490&amp;chapterid=20250">Jackson Community College</a>.</span>,
      <span>More than half of college presidents surveyed said online courses are just as valuable as traditional courses, according to a <a href="http://www.pewsocialtrends.org/2011/08/28/the-digital-revolution-and-higher-education/">Pew Research Center</a> survey.</span>,
      <span>In 2010, 66% of chief academic leaders deemed online education as good or better than face-to-face higher education, compared to 57% in 2003, according to a Sloan <a href="http://sloanconsortium.org/publications/survey/pdf/class_differences.pdf">survey</a>.</span>,
    ]
  },
  {
    title: 'You Can Save Money By Being an Online Student',
    content: [
      <span>Traditional private institutions average $128,726 for undergraduate tuition, room, and board, according to the <a href="http://nces.ed.gov/fastfacts/display.asp?id=76">National Center for Education Statistics</a>. This takes into account the annual prices for the 2009-10 academic year and multiples them by four.</span>,
      <span>Traditional public institutions average $51,216 for undergraduate tuition, room, and board, according to the <a href="http://nces.ed.gov/fastfacts/display.asp?id=76">National Center for Education Statistics</a>. This takes into account the annual prices for the 2009-10 academic year and multiples them by four.</span>,
      <span>Online degrees can cost more or less than a traditional degree, depending on which institution you choose. Common online bachelor's degree programs can cost anywhere from around $23,000 to around $68,000, depending on the school, according to a tuition comparison sheet of popular online universities provided by <a href="http://www.wgu.edu/tuition_financial_aid/overview">Western Governors University</a>. Non-profit and public institutions tend to offer lower costs for online programs overall.</span>,
      <span>You will not need to factor in room and board costs or commuting costs in a fully online program, which can lead to cost savings. Room and board ranges from $7,500 to $9,000 a year, depending on whether you attend a public or private college, according to <a href="http://www.scholarships.com/resources/campus-life/college-costs/room-and-board/">Scholarships.com</a>.</span>,
      <span>Government financial aid programs can be used to finance an online education, provided the online institution is accredited. Accreditation is one of many considerations that help determine if an institution is eligible for federal assistance, according to the <a href="http://www2.ed.gov/admins/finaid/accred/accreditation_pg2.html">U.S. Department of Education</a>.</span>,
    ]
  },
  {
    title: 'You Need to Do Your Own Research',
    content: [
      <span>Research starting salaries in the typical career fields associated with your chosen degree plan so that you won't get lured into enrolling in an online program based on false promises of the money you could make. A good resource for salary information is the <a href="http://www.bls.gov/">U.S. Bureau of Labor Statistics</a>.</span>,
      <span>Learn whether the income you are likely to make will be enough to offset the cost of your online education. According to <a href="http://www.insidehighered.com/views/2011/09/30/connelly_angel_essay_on_for_profit_colleges_putting_future_at_risk"><em>Inside Higher Ed</em></a>, many bad factors in the for-profit sector have left students saddled with debt and unable to repay their federal loans, resulting in an alarmingly high student loan default rate.</span>,
      <span>Look into any degree requirements you must fulfill for you to enter your chosen career, and then carefully evaluate whether the online program will adequately fulfill those requirements. This <a href="http://chronicle.com/blogs/brainstorm/college-inc/23850">article</a> in the <i>Chronicle of Higher Education</i> highlights a documentary showing the plight of three nursing students who were required to complete a pediatric clinical rotation to become nurses. Unfortunately, their school had them do the rotation in a daycare center instead, effectively leaving them unable to become licensed, unable to get a job, and yet still loaded with student debt.</span>,
      <span>Ask about your company's tuition reimbursement benefits, and find out whether it covers online learning. <a href="http://www.investopedia.com/articles/pf/08/tuition-reimbursement.asp#axzz1bM2Affxq">Investopedia</a> points out that many employers make their workers sign a contract saying that the employee must work for the company for a set amount of time and maintain a certain GPA to be reimbursed, and you don't want to be surprised by any fine print.</span>,
      <span>Research the school's retention and graduation rates to get an indication of the school's quality. A good resource for doing this is a consumer information tool called <a href="http://nces.ed.gov/collegenavigator/">College Navigator</a>.</span>
    ]
  },
  {
    title: 'Your College Needs to Be Accredited',
    content: [
      <span>Colleges &mdash; both online and offline &mdash; must be accredited by an agency recognized by the U.S. Department of Education (USDE) to participate in federal student financial aid programs, according to the <a href="http://www2.ed.gov/admins/finaid/accred/accreditation_pg2.html">USDE</a>.</span>,
      <span>Accreditation agencies provide oversight and evaluate the quality of education at the institutions they cover, the USDE points out. Accreditation helps students pick acceptable institutions and is also often a factor in whether credit will be allowed to transfer from one school to another.</span>,
      <span>Before enrolling in any course, check the U.S. Department of Education's database of accredited schools and programs <a href="http://ope.ed.gov/accreditation/Search.aspx">here</a>. If the school is not listed, contact the institution to find out why. Some schools do not seek accreditation, but still offer quality courses; however, financial aid is not available from the federal government for unaccredited schools.</span>,
      <span>Check the U.S. Department of Education's list of reputable accreditation agencies <a href="http://www2.ed.gov/admins/finaid/accred/accreditation_pg6.html">here</a> or look at a similar <a href="http://www.chea.org/Directories/index.asp">directory</a> provided by the Council for Higher of Education Accreditation (CHEA).</span>
    ]
  },
  {
    title: 'Diploma Mills Threaten Your Investment and Future',
    content: [
      <span>As online education has expanded, so has the number of scams. The rise of the Internet has made it much easier for a skilled Web developer to put up a convincing-looking website, line their pockets with "tuition" from consumers, and then close up shop without providing anything of value in return, according to <a href="http://www.chea.org/degreemills/frmPaper.htm">CHEA</a>.</span>,
      <span>Promises of degrees with little or no work, or based solely on life experience, are too good to be true. Check out CHEA's <a href="http://www.chea.org/degreemills/frmPaper.htm">list of questions</a> to ask yourself to determine if you are considering a degree mill.</span>,
      <span>Misrepresentation of degree qualifications might do more than damage reputations; in some states, it might bring fines and jail time. For instance, in the state of Washington, you could receive a Class C felony of fraud, face up to five years in prison, and a $10,000 fine if you falsely claim in writing to have an accredited degree, according to the <a href="http://www.seattlepi.com/local/article/Lying-on-resume-could-land-you-in-jail-1197551.php">Seattle Post-Intelligencer</a>.</span>,
      <span>Prospective students should research schools thoroughly using the U.S. Department of Education, CHEA, and the Better Business Bureau before sharing any information with the institution.</span>
    ]
  }
]

export default function List() {
  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md mt-5">
      <ul className="divide-y divide-gray-200">
        {statistics.map((stat) => (
          <li key={stat.title}>

            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button className="hover:bg-gray-50 cursor-pointer flex grow items-center justify-between px-4 py-4 sm:px-6 w-full">
                    <h3 className="text-lg font-bold text-sky-700">{stat.title}</h3>
                    <div>
                      <ChevronRightIcon
                        aria-hidden="true"
                        className={`${open ? "transform rotate-90 h-5 w-5 text-gray-400" : "h-5 w-5 text-gray-400"}`}
                      />
                    </div>
                  </Disclosure.Button>

                  <Disclosure.Panel>
                    <div className="p-4">
                      <div className="space-y-4 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-4 md:gap-y-4">
                        {stat.content.map((c) => (
                          <div key={c} className="flex">
                            <div>
                              <LightningBoltIcon className="h-4 w-4 mt-1 text-gray-600" aria-hidden="true" />
                            </div>
                            <p className="ml-2 text-sm text-gray-500">{c}</p>
                          </div>
                        ))}
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </li>
        ))}
      </ul>
    </div>
  )
}
