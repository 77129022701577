const navigation = [
    { name: 'Privacy Policy', href: '/privacy-policy' },
    { name: 'Advertising Disclosure', href: '/advertising-disclosure' },
    { name: 'Terms of Service', href: '/terms-of-service' },
    { name: 'Consumer Health Data Privacy Policy', href: '/consumer-health-data-privacy-policy' },
    { name: 'Do Not Sell or Share My Personal Information', href: '/do-not-sell-my-personal-information'}
  ]

export default function Footer() {
  return (
    <footer className="border-t border-gray-200">
      <div className="max-w-7xl mx-auto py-6 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="-mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
          {navigation.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <a href={item.href} className="text-sm font-semibold text-sky-700 hover:text-sky-900 no-underline">
                {item.name}
              </a>
            </div>
          ))}
        <button id="ot-sdk-btn" className="ot-sdk-show-settings">Cookie Settings</button>
        </nav>
        <p className="mt-4 text-center text-base text-gray-500">&copy; 2022 CollegeDegrees.com</p>
      </div>
    </footer>
  )
}


