import { Link } from 'react-router-dom'
import Page from '../components/Layout/Page'
const Home = () => {
  return (
    <Page title='The Consumer Voice in the College Decision'>
      {/* <div className="mt-5 prose prose-sky text-gray-500"> */}
      <div className="mt-5 prose max-w-none text-gray-500">
        <p>
          It's easy to forget that a college degree should be about freedom.
          Because higher education is such a huge investment of your time, energy, and money, the consumer voice often gets drowned out.
        </p>
        <p>
          Online education has emerged as the consumer's choice for higher education that reflects the modern reality.
          As a leader in this rapidly growing field, CollegeDegrees.com partners up with hundreds of accredited online colleges and universities so that we can help you find the best degree program for your interests and goals.
        </p>
        <p>
          Our schools offer the most cutting-edge college programs that provide you with the practical career training you need for today's job world.
          Our online tools and resources allow you to compare schools and degree programs to make an informed decision on the next stage of your education.
          Find out more today, and see for yourself how much college has changed.
        </p>
      </div>
      <div className="max-w-7xl mt-8 mx-auto text-center py-6 px-4 sm:px-6 lg:px-8 bg-gray-200 rounded-2xl shadow-xl">
        <h2 className="text-2xl font-bold tracking-tight sm:text-4xl text-sky-600">
          Partner with us!
        </h2>
        <p className="mt-4 text-lg leading-6 text-gray-600">
          We are always looking for new partners in the online education field. Find out more today!
        </p>
        <div className="mt-8 xs:flex justify-center">
          <div className="inline-flex rounded-md shadow">
            <Link
              to="/affiliate-program"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-sky-700 hover:bg-sky-600 no-underline hover:text-white"
            >
              Become an Affiliate
            </Link>
          </div>
          <div className="mt-3 xs:mt-0 xs:ml-3 inline-flex">
            <Link
              to="/school-partners"
              className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-sky-700 bg-gray-50 hover:bg-gray-200 no-underline"
            >
              Our School Partners
            </Link>
          </div>
        </div>
      </div>
    </Page>
  )
}

export default Home