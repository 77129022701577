import { NavLink, useLocation, Link } from 'react-router-dom'
import { Disclosure } from '@headlessui/react'
import { AcademicCapIcon, MenuIcon, XIcon } from '@heroicons/react/outline'

const navigation = [
  { name: 'Home', href: '/' },
  { name: 'Consumer Facts', href: '/consumer-facts' },
  { name: 'About Us', href: '/about' },
  { name: 'School Partners', href: '/school-partners' },
  { name: 'Become an Affiliate', href: '/affiliate-program' },
  { name: 'Contact Us', href: '/contact' },
]
function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  const { pathname } = useLocation();

  return (
    <Disclosure as="header" className="bg-white shadow sticky top-0 z-50">
      {({ open }) => (
        <>
          <div className="max-w-7xl mx-auto px-2 sm:px-4 sm:divide-y sm:divide-gray-200">
            <div className="relative h-16 flex justify-between sm:justify-center">
              <div className="relative z-10 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <div className="p-2 inline-flex items-center justify-center hidden xs:block">
                  <div className="block h-6 w-6" aria-hidden="true" />
                </div>
              </div>
              <div className="relative z-10 px-2 flex sm:px-0">
                <div className="flex-shrink-0 flex items-center">
                  <AcademicCapIcon className="block h-8 w-8 text-sky-600 hidden xxs:block" aria-hidden="true" />
                <span className="block w-auto font-bold align-middle text-2xl text-sky-600 ml-1">CollegeDegrees.com</span>

                </div>
              </div>
              <div className="relative z-10 flex items-center sm:hidden">
                {/* Mobile menu button */}
                <Disclosure.Button className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-sky-500">
                  <span className="sr-only">Open menu</span>
                  {open ? (
                    <XIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>

            </div>
            <nav className="hidden sm:flex sm:justify-center" aria-label="Global">

              <div className="hidden sm:block flex items-center">
                <div className="flex h-12 items-center">
                  {navigation.map((item) => (
                    <NavLink
                      key={item.name}
                      to={item.href}
                      exact="true"
                      className={({ isActive }) => {
                        if (isActive) {
                          return 'bg-gray-100 text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-xs font-medium no-underline'
                        }
                        return 'text-gray-900 hover:bg-gray-50 hover:text-gray-900 rounded-md py-2 px-3 inline-flex items-center text-xs font-medium no-underline'
                      }}
                    >
                      {item.name}
                    </NavLink>
                  ))}
                </div>
              </div>
            </nav>
          </div>

          <Disclosure.Panel as="nav" className="sm:hidden" aria-label="Global">
            <div className="pt-2 pb-3 px-2 space-y-1">
              {navigation.map((item) => (
                <Disclosure.Button
                  key={item.name}
                  as={Link}
                  to={item.href}
                  className={classNames(
                    pathname === item.href ? 'bg-gray-100 text-gray-900' : 'text-gray-900 hover:bg-gray-50 hover:text-gray-900',
                    'block rounded-md py-2 px-3 text-base font-medium no-underline'
                  )}
                  aria-current={item.current ? 'page' : undefined}
                >
                  {item.name}
                </Disclosure.Button>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  )
}
